@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
html {
  font-family: "Roboto", "sans-serif";
  font-size: 62.5%;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#quote-box {
  padding: 10em;
  margin: 10em;
  background-color: #f6f6f6;
  -webkit-filter: drop-shadow(10px 5px 20px white);
          filter: drop-shadow(10px 5px 20px white);
  border-radius: 5px;
}

#text {
  font-size: 3.5rem;
  text-align: center;
  line-height: 60px;
  transition: all 2.2s ease;
}

#author {
  font-size: 3.2rem;
  text-align: end;
  transition: all 2.2s ease;
}

#tweet-quote {
  text-decoration: none;
  color: black;
}

#buttonsDiv {
  display: flex;
  justify-content: start;
  grid-gap: 2em;
  gap: 2em;
}

#buttonsDiv button {
  margin-top: 1em;
  padding: 1.2em 2em;
  border-radius: 6px;
  color: #f6f6f6;
  cursor: pointer;
  border: none;
  font-weight: 600;
  box-shadow: 1px 1px 10px black;
}

#buttonDiv button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#new-quote {
  font-weight: 600;
  margin-left: auto;
  box-shadow: 1px 1px 10px black;
}

#new-quote:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

